import { useState } from 'react';

import { Popover, PopoverContent } from '@carbon/react';
import { X } from 'lucide-react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { CustomInput } from '#/common/components';
import { useFormikForm } from '#/common/hooks';
import { apiEndpoints, queryKeys, useGetQuery, usePostMutation } from '#/services';
import { MAX_FILE_SIZE, MAX_LENGTH_LIMIT } from '#/utils/constants';
import { capitalizeText, toSnakeCase } from '#/utils/helperFunctions';

function ChatbotWidget({ leadId }) {
  const [showPreview, setShowPreview] = useState(false);
  const [fileFieldName, setFileFieldName] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [isFieldSubmit, setIsFieldSubmit] = useState(false);

  const handlePreviewToggle = () => {
    setShowPreview(!showPreview);
    resetForm();
    setIsFieldSubmit(!isFieldSubmit);
  };

  const { data: stickyBtnDetail } = useGetQuery({
    key: queryKeys.STICKY_BTN_DETAIL,
    url: `${apiEndpoints.GET_STICKY_BUTTON}${leadId}/`,
    options: {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!leadId,
    },
  });

  const onError = ({ response: { data } } = {}) => {
    const { detail, non_field_errors, image } = data || {};

    const errorMessage = image[0] || detail || non_field_errors[0] || 'An error occurred';

    toast.error(errorMessage);
  };

  const onSuccess = () => {
    setIsFieldSubmit(false);
    setShowPreview(false);
    toast.success('Thank you for your submission!');
  };

  const { mutate: onLeadSubmit } = usePostMutation({
    url: apiEndpoints.LEAD_RECORD,
    onSuccess,
    onError,
  });

  const onUploadFileSuccess = ({ data }) => {
    if (data?.file) {
      setUploadedFiles(prev => ({
        ...prev,
        [fileFieldName]: data.file,
      }));
    }
  };

  const { mutate: uploadFile, isPending: isUploadFileLoading } = usePostMutation({
    url: apiEndpoints.UPLOAD_FILE,
    onSuccess: onUploadFileSuccess,
    onError,
  });

  const handleFileChange = fieldName => e => {
    const { files } = e.target;
    if (!files || files.length === 0) return;

    const file = files[0];

    if (file.size > MAX_FILE_SIZE) {
      toast.error('File size exceeds 10MB. Please upload a smaller file.');
      setIsFieldSubmit(!isFieldSubmit);
      setUploadedFiles({});
      setFieldValue(fieldName, '');
      return;
    }

    const formData = new FormData();
    formData.append('file', file, file.name);

    if (fieldName) {
      setFileFieldName(fieldName);
    }

    uploadFile({ payload: formData });

    setFieldValue(fieldName, file);
  };

  const handleTelInputChange = fieldName => e => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      setFieldValue(fieldName, value);
    }
  };

  const {
    backgroundColor = '',
    label = '',
    labelColor = '',
    lead = {},
    position = 'left',
    status = 'INACTIVE',
  } = stickyBtnDetail?.data || {};

  const generateValidationSchema = fields => {
    if (!fields || !fields.length) return Yup.object({});

    const schema = {};

    fields.forEach(field => {
      if (!field) return;

      const { label, type } = field;
      let fieldSchema;

      const fieldName = label;

      if (!fieldName) return;

      switch (type?.toLowerCase()) {
      case 'email':
        fieldSchema = Yup.string().email('Invalid email format').required('This field is required');
        break;
      case 'number':
        fieldSchema = Yup.string()
          .matches(/^\d+$/, 'Only numeric characters are allowed')
          .required('This field is required');
        break;
      default:
        fieldSchema = Yup.string().required('This field is required');
      }

      schema[fieldName] = fieldSchema;
    });

    return Yup.object().shape(schema);
  };

  const generateInitialValues = fields => {
    if (!fields || !fields.length) return {};

    const initialValues = {};

    fields.forEach(field => {
      if (field && field.label) {
        initialValues[field.label] = '';
      }
    });

    return initialValues;
  };

  const onLeadFormSubmit = values => {
    const payload = {
      chatbotLead: lead?.id,
      response: toSnakeCase({
        ...values,
        ...uploadedFiles,
      }),
    };
    onLeadSubmit({ payload });
    resetForm();
    setIsFieldSubmit(true);
    setUploadedFiles({});
  };

  const { values, setFieldValue, errors, touched, handleChange, submitForm, resetForm } = useFormikForm(
    generateInitialValues(lead?.fields),
    onLeadFormSubmit,
    generateValidationSchema(lead?.fields)
  );

  return (
    <Popover
      className={`sticky-btn-preview ${status === 'INACTIVE' ? 'hide' : ''}`}
      style={position === 'left' ? { left: 0 } : { right: 0 }}
      open={showPreview}
      autoAlign={true}
      align={position === 'left' ? 'right-center' : 'left-center'}
      caret={false}
    >
      <div className="preview" onClick={handlePreviewToggle}>
        <div className="text-wrapper">
          <p
            className="text"
            style={{
              backgroundColor: backgroundColor,
              color: labelColor,
              borderRadius: position === 'left' ? '8px 0 0 8px' : '0 8px 8px 0',
            }}
          >
            {label}
          </p>
        </div>
      </div>
      <PopoverContent>
        <div className="lead-box">
          <div className="box">
            <h4>{lead?.name}</h4>
            <span className="close-icon" onClick={handlePreviewToggle}>
              <X />
            </span>
            <div className="fields">
              {lead?.fields?.map(field => {
                if (!field) return null;

                const { label, type } = field || {};
                const fieldType = type?.toLowerCase();

                const isFileField = fieldType === 'upload';
                const isNumberField = fieldType === 'number';

                return (
                  <CustomInput
                    key={isFileField ? (isFieldSubmit ? 'uploaded' : 'empty') : label}
                    type={isFileField ? 'file' : isNumberField ? 'tel' : fieldType}
                    name={label}
                    placeholder={capitalizeText(label) || 'Enter value'}
                    onChange={
                      isFileField ? handleFileChange(label) : isNumberField ? handleTelInputChange(label) : handleChange
                    }
                    isError={errors[label] && touched[label]}
                    errorMessage={errors[label]}
                    value={isFileField ? undefined : values[label] || ''}
                    maxLength={MAX_LENGTH_LIMIT}
                    onKeyPress={
                      isNumberField
                        ? e => {
                          if (!/\d/.test(e.key)) {
                            e.preventDefault();
                          }
                        }
                        : undefined
                    }
                  />
                );
              })}
              <button
                className="btn btn-primary"
                style={{ backgroundColor: backgroundColor, color: labelColor }}
                disabled={isUploadFileLoading}
                onClick={submitForm}
              >
                Submit
              </button>
            </div>
          </div>
          <p className="powered-by">
            Powered by <span>Booosted.fi</span>
          </p>
        </div>
      </PopoverContent>
    </Popover>
  );
}

export default ChatbotWidget;
